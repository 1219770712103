import React, {useCallback, useEffect, useState} from "react";
import UploadService from "../../../services/upload-files.service";
import {connect} from "react-redux";
// import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

// Style für DataTreeView
// const useStyles = makeStyles({
//     root: {
//         textAlign: "left",
//         fontFamily: "poppins",
//     },
//     lightButton: {
//         float: "right",
//         fontWeight: "bold",
//     },
//     darkButton: {
//         float: "right",
//         color: "#e5e5e5",
//         fontWeight: "bold",
//     },
//     katalogView: {
//         width: "95%",
//         height: "90%",
//         margin: "15px",
//         padding: "20px",
//         float: "left",
//         border: "2px solid #B4AF03",
//         borderRadius: "25px",
//     },
//     btListe: {
//         width: "60vw",
//         maxHeight: "65vh",
//         overflowY: "scroll",
//         margin: "15px",
//         padding: "10px",
//         float: "left",
//     },
//     editView: {
//         height: "fit-content",
//         display: "flex",
//         alignItems: "left",
//         padding: "5px",
//         border: "2px solid #B4AF03",
//         borderRadius: "25px",
//     },
// });


function UploadFiles({theRealm}){
    const [selectedFiles, setSelectedFiles] = useState('');
    const [currentFile, setCurrentFile] = useState(undefined);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [fileInfos, setFileInfos] = useState([]);
    // const classes = useStyles();

    const token = sessionStorage.getItem('kctoken')

    useEffect(() => {
        setCurrentFile(selectedFiles[0]);
    }, [selectedFiles]);

    useEffect(()=>{

        UploadService.getLocaleFiles(token, theRealm)
            .then((files) => {
                // console.dir(files)
                setFileInfos(files.data)
            });
    },[token, theRealm]);
    function initFiles(files) {
        setSelectedFiles(files);
        // setCurrentFile(selectedFiles[0]);
    }

    const upload = useCallback(async() => {
        // console.log('hello upload');
        // console.dir(selectedFiles);
        // setCurrentFile(selectedFiles[0]);
        setProgress(0);
        // console.log('hello currentFile');
        // console.dir(selectedFiles[0]);
        // console.log('hello currentFile 2');
        // console.dir(currentFile);
        UploadService.upload(currentFile, token, theRealm, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total))

        })
            .then((response) => {
                 console.dir(response)
                setMessage(response.data.message)
                return UploadService.getLocaleFiles(token, theRealm);
            })
            .then((files) => {
                 console.dir(files)
                setFileInfos(files.data)
            })
            .catch(() => {
                setProgress(0);
                setMessage("Fehler: die Datei konnte nicht geladen werden!");
                setCurrentFile(undefined)
            });
    }, [currentFile, token, theRealm]);

    const deleteFile = useCallback(async(fileName) => {
        UploadService.deleteLocaleFile(fileName, token, theRealm)
            .then((response) => {
                // console.dir(response)
                setMessage(response.data.message)
                return UploadService.getLocaleFiles(token,theRealm);
            })
            .then((files) => {
                // console.dir(files)
                setFileInfos(files.data)
            })
            .catch(() => {
                setProgress(0);
                setMessage("Fehler beim Upload. Die Datei konnte nicht auf dem Server gespeichert werden!");
                setCurrentFile(undefined)
            });
    },[token, theRealm]);


    return (
        <div>
            {currentFile && (
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: progress + "%" }}
                    >
                        {progress}%
                    </div>
                </div>
            )}

            <label className="btn btn-default">
                <input type="file" onChange={(e) => initFiles(e.target.files)} />
            </label>

            <button className="btn btn-success"
                    disabled={!selectedFiles}
                    onClick={() => upload()}
            >
                Upload
            </button>

            <div className="alert alert-light" role="alert">
                {message}
            </div>

            <div className="card">
                <div className="card-header">Liste der möglichen Import-Dateien</div>
                <ul className="list-group list-group-flush">
                    {fileInfos &&
                    fileInfos.map((file, index) => (
                        <li className="list-group-item" key={index}>
                            <Link to={`/filemapping/${file.name}`}>{file.name}</Link>
                            <Button onClick={()=>deleteFile(file.name)}>Löschen</Button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function mapState({theRealm}) {
    return {
        theRealm: theRealm
    }
}

export default connect(
    mapState,
    null
)(UploadFiles);